import React, { useContext, useEffect, useRef, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  FormGroup,
  Form,
  UncontrolledAlert,
  Label,
  Button,
} from "reactstrap"

import { connect } from "react-redux"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { Link, useNavigate, useParams } from "react-router-dom"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import Dropzone from "react-dropzone"
import { groups } from "common/data"
import { ProfileContext } from "App"

const SingleSubmission = props => {
  const { id } = useParams()

  const { profileUpdate, setProfileUpdate } = useContext(ProfileContext)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [mobile_no, setMobileNo] = useState("")
  const [country, setCountry] = useState("")
  const [city, setCity] = useState("")
  const [story, setStory] = useState("")
  const [products, setProducts] = useState("")
  const [insta, setInsta] = useState("")
  const [facebook, setFacebook] = useState("")

  const [image, setImage] = useState([])

  document.title = "ExystNow Admin - Submission Detail"

  const breadcrumbItems = [
    { title: "Submissions", link: "submissions" },
    { title: "Details", link: "#" },
    // { title: "Form Elements", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Details", breadcrumbItems)
  })

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/form/entry/${id}`,
        )

        const json = await response.json()

        if (response.ok) {
          setName(json.data.full_name || "")
          setEmail(json.data.email || "")
          setCountry(json.data.country || "")
          setCity(json.data.city || "")
          setStory(json.data.story || "")
          setInsta(json.data.insta || "")
          setFacebook(json.data.facebook || "")
          setProducts(json.data.products || "")
          setMobileNo(json.data.contact_no || "")
          setImage(json.data.files || [])
        } else {
        }
      } catch (error) {
        console.error(error)
      } finally {
        // Clear form fields if necessary
      }
    }

    fetchDetails()
  }, [id])

  const groups = [
    {
      label: "Admin",
      value: 1,
    },
    {
      label: "Data Entry Specialist",
      value: 2,
    },
  ]

  return (
    <React.Fragment>
      {/* form */}
      <Row className="">
        <Col lg="12">
          <Card>
            <CardBody className="text-center">
              <div className="row d-flex justify-content-evenly">
                {image.length > 0 &&
                  image.map(img => (
                    <div className="col-sm-12 col-md-4">
                      <img
                        className="rounded-circle mt-4 mt-sm-0"
                        height="200px"
                        width="200px"
                        alt="uploaded preview"
                        role="button"
                        src={img.secure_url}
                        style={{ objectFit: "cover" }}
                      />
                    </div>
                  ))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="">
        <Col lg="12">
          <Card>
            <CardBody>
              {/* <h4 className="card-title">Information</h4> */}

              <AvForm className="needs-validation mt-4">
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Name</Label>
                      <AvField
                        name="name"
                        type="text"
                        errorMessage="Enter Valid Name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={name}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Mobile</Label>
                      <AvField
                        name="mobile"
                        type="text"
                        errorMessage="Enter Valid Mobile"
                        className="form-control"
                        id="validationCustom01"
                        value={mobile_no}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Email</Label>
                      <AvField
                        name="email"
                        type="email"
                        errorMessage="Enter Valid Email"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={email}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Country</Label>
                      <AvField
                        name="password"
                        value={country}
                        type="text"
                        errorMessage="Enter Valid Password"
                        className="form-control"
                        id="validationCustom01"
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">City</Label>
                      <AvField
                        name="email"
                        errorMessage="Enter Valid Email"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={city}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Products</Label>
                      <AvField
                        name="password"
                        value={products}
                        disabled
                        type="text"
                        errorMessage="Enter Valid Password"
                        className="form-control"
                        id="validationCustom01"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Story</Label>
                      <AvField
                        name="email"
                        type="textarea"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={story}
                        rows="4"
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Instagram Link</Label>
                      <a href={insta} target="_blank">
                        {insta}
                      </a>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Facebook Link</Label>
                      <a href={facebook} target="_blank">
                        {facebook}
                      </a>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* end form */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(SingleSubmission)
