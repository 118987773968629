import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import { connect } from "react-redux"
import Swal from "sweetalert2"
// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"

import { Link } from "react-router-dom"
import { format } from "date-fns"

const AllSubmissions = props => {
  document.title = "Exyst Admin - All Submissions"

  const breadcrumbItems = [
    { title: "Submissions", link: "#" },
    { title: "All Sumissions", link: "#" },
  ]

  const [data1, setData] = useState(null)
  const [records, setRecords] = useState([])
  const [update, setUpdate] = useState(0)
  const token = localStorage.getItem("token")
  const truncateText = (text, length = 50) => 
    text.length > length ? text.substring(0, length) + "..." : text;
  useEffect(() => {
    props.setBreadcrumbItems("Manage Submissions", breadcrumbItems)
    console.log("2 bar")
  }, [props])

  useEffect(() => {
    const fetchAllCategories = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/form/entries`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const json = await response.json();
  
      if (response.ok) {
        const sortedData = json.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setData(sortedData);
        setRecords(sortedData);
      } else {
        setRecords([]);
      }
    };
  
    fetchAllCategories();
  }, [update]);

  const data = {
    columns: [
      // {
      //   label: "Image",
      //   field: "image",
      //   sort: false,
      //   width: 150,
      // },
      {
        label: "Submission Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "full_name",
   
        width: 150,
      },

      {
        label: "Email",
        field: "email",

        width: 150,
      },
      // {
      //   label: "Story",
      //   field: "story",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Products",
      //   field: "products",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Mobile",
        field: "contact_no",
    
        width: 150,
      },
      {
        label: "Country",
        field: "country",
   
        width: 150,
      },
      {
        label: "City",
        field: "city",
    
        width: 150,
      },

 

      {
        label: "Action",
        field: "action",
        sort: false,
        width: 100,
      },
    ],
    rows: records.map(row => ({
        full_name: row.full_name,
        email: row.email,
        contact_no: row.contact_no,
        country: row.country,
        city: row.city,
        products: row.products,
        date: format(row.createdAt, "dd MMM, yyyy"),
        story: truncateText(row.story), // Truncate story here
      
  
 
      // image: (
      //   <>
      //     <img
      //       height="50px"
            
      //       // className="avatar-sm rounded bg-light"
          
      //       src={row.bank_image_url}
      //     ></img>
      //   </>
      // ),
      action: (
      
        <div className="d-flex justify-content-end gap-2">
              <Link
            to={`/single-submission/${row._id}`}
            className="btn btn-sm btn-primary"
          >
            View Details
          </Link>{" "}
        
          <button
            onClick={() => handleDelete(row._id)}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>
        </div>
      
      ),
    })),
  }

  const handleDelete = myId => {
    console.log(myId)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(result => {
        if (result.isConfirmed) {
          const deleteUser = async d_id => {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/form/entries/delete/${d_id}`,
              {
                method: "DELETE",
             
              },
            )
            setUpdate(update + 1)
          }
          deleteUser(myId)
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Data has been deleted.",
            icon: "success",
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Data is safe :)",
            icon: "error",
          })
        }
      })
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4 d-flex justify-content-between">All Submissions</CardTitle>

              <MDBDataTable
                responsive
                bordered
                data={data}
                data-mdb-loading="true"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AllSubmissions)
