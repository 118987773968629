import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link, useLocation } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {

  const location = useLocation()

  useEffect(()=>{

    console.log(location.pathname)
  }, [location])
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // const removeActivation = (items) => {
  //   for (var i = 0; i < items.length; ++i) {
  //     var item = items[i];
  //     const parent = items[i].parentElement;

  //     if (item && item.classList.contains("active")) {
  //       item.classList.remove("active");
  //     }
  //     if (parent) {
  //       const parent2El =
  //         parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
  //           ? parent.childNodes[1]
  //           : null;
  //       if (parent2El && parent2El.id !== "side-menu") {
  //         parent2El.classList.remove("mm-show");
  //       }

  //       parent.classList.remove("mm-active");
  //       const parent2 = parent.parentElement;

  //       if (parent2) {
  //         parent2.classList.remove("mm-show");

  //         const parent3 = parent2.parentElement;
  //         if (parent3) {
  //           parent3.classList.remove("mm-active"); // li
  //           parent3.childNodes[0].classList.remove("mm-active");

  //           const parent4 = parent3.parentElement; // ul
  //           if (parent4) {
  //             parent4.classList.remove("mm-show"); // ul
  //             const parent5 = parent4.parentElement;
  //             if (parent5) {
  //               parent5.classList.remove("mm-show"); // li
  //               parent5.childNodes[0].classList.remove("mm-active"); // a tag
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // };

  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    // removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Main")} </li>
            <li>
              <Link to="/submissions" className={`waves-effect ${location.pathname.includes("submission") ? "active" : ""}`}>
                <i className="mdi mdi-view-dashboard"></i>
                {/* <span className="badge rounded-pill bg-primary float-end">2</span> */}
                <span>{props.t("Submission")}</span>
              </Link>
            </li>

{/*     
            <li>
              <Link to="/all-banks" className="waves-effect">
                <i className="mdi mdi-bank"></i>
       
                <span>{props.t("Banks")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-noodles"></i>
                <span>{props.t("Recipes")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/all-pending-recipes">{props.t("Pending Recipes")}</Link>
                </li>
                <li>
                  <Link to="/all-approved-recipes">{props.t("Approved Recipes")}</Link>
                </li>
                <li>
                  <Link to="/all-rejected-recipes">{props.t("Rejected Recipes")} </Link>
                </li>
                <li>
                  <Link to="/all-recipe-categories">{props.t("Categories")} </Link>
                </li>
              </ul>
            </li>
            <li className="menu-title">{props.t("Restaurants")}</li>
            <li>
              <Link to="/all-restaurants" className="waves-effect">
                <i className="mdi mdi-table-chair"></i>
       
                <span>{props.t("Restaurants")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-shape"></i>
                <span>{props.t("Categories")}</span>
              </Link>
              <ul className="sub-menu" >
               
                <li>
                  <Link to="/all-restaurant-categories">{props.t("Restaurant Categories")}</Link>
                </li>
                <li>
                  <Link to="/all-restaurant-dish-categories">{props.t("Dish Categories")} </Link>
                </li>
                <li>
                  <Link to="/all-restaurant-dish-cuisines">{props.t("Dish Cuisines")} </Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">{props.t("Users")}</li>
            <li>
              <Link to="/all-admins" className="waves-effect">
                <i className="mdi mdi-crown"></i>
       
                <span>{props.t("Admins")}</span>
              </Link>
            </li>
            <li>
              <Link to="/all-users" className="waves-effect">
                <i className="mdi mdi-account-group"></i>
       
                <span>{props.t("Customers")}</span>
              </Link>
            </li>

            <li className="menu-title">{props.t("Reviews")}</li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-message-draw"></i>
                <span>{props.t("Recipe Reviews")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/all-pending-recipe-reviews">{props.t("Pending Reviews")}</Link>
                </li>
                <li>
                  <Link to="/all-approved-recipe-reviews">{props.t("Approved Reviews")}</Link>
                </li>
                <li>
                  <Link to="/all-rejected-recipe-reviews">{props.t("Rejected Reviews")} </Link>
                </li>
            
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-message-draw"></i>
                <span>{props.t("Restaurant Reviews")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/all-pending-restaurant-reviews">{props.t("Pending Reviews")}</Link>
                </li>
                <li>
                  <Link to="/all-approved-restaurant-reviews">{props.t("Approved Reviews")}</Link>
                </li>
                <li>
                  <Link to="/all-rejected-restaurant-reviews">{props.t("Rejected Reviews")} </Link>
                </li>
            
              </ul>
            </li>

            <li className="menu-title">{props.t("Media")}</li>
            <li>
              <Link to="/all-banners" className="waves-effect">
                <i className="mdi mdi-image-multiple"></i>
       
                <span>{props.t("Banners")}</span>
              </Link>
            </li> */}

            {/* <li>
              <Link to="/calendar" className=" waves-effect">
                <i className="mdi mdi-calendar-check"></i>
                <span>{props.t("Calendar")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/add-restaurant-category" className="waves-effect">
                <i className="mdi mdi-calendar-check"></i>
                <span>{props.t("Add New Category")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/all-restaurant-categories" className="waves-effect">
                <i className="mdi mdi-calendar-check"></i>
                <span>{props.t("Categories")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/add-restaurant" className="waves-effect">
                <i className="mdi mdi-calendar-check"></i>
                <span>{props.t("Add New Restaurant")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/all-restaurants" className="waves-effect">
                <i className="mdi mdi-calendar-check"></i>
                <span>{props.t("Restaurants")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/add-restaurant-location" className="waves-effect">
                <i className="mdi mdi-calendar-check"></i>
                <span>{props.t("Add Restaurant Location")}</span>
              </Link>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-email-outline"></i>
                <span>{props.t("Email")}</span>
              </Link>
              <ul className="sub-menu" >
                <li>
                  <Link to="/email-inbox">{props.t("Inbox")}</Link>
                </li>
                <li>
                  <Link to="/email-read">{props.t("Email Read")} </Link>
                </li>
                <li>
                  <Link to="/email-compose">{props.t("Email Compose")} </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/chat" className=" waves-effect">
                <i className="mdi mdi-chat-processing-outline"></i>
                <span className="badge rounded-pill bg-danger float-end">Hot</span>
                <span>Chat</span>
              </Link>
            </li>

            <li>
              <Link to="/kanbanboard" className=" waves-effect">
                <i className="mdi mdi-billboard"></i>
                <span className="badge rounded-pill bg-success float-end">New</span>
                <span>Kanban Board</span>
              </Link>
            </li>

            <li className="menu-title">{props.t("Components")}</li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-buffer"></i>
                <span>{props.t("UI Elements")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ui-alerts">{props.t("Alerts")}</Link>
                </li>
                <li>
                  <Link to="/ui-buttons">{props.t("Buttons")}</Link>
                </li>
                <li>
                  <Link to="/ui-badge">{props.t("Badge")}</Link>
                </li>
                <li>
                  <Link to="/ui-cards">{props.t("Cards")}</Link>
                </li>
                <li>
                  <Link to="/ui-carousel">{props.t("Carousel")}</Link>
                </li>
                <li>
                  <Link to="/ui-dropdowns">{props.t("Dropdowns")}</Link>
                </li>
                <li>
                  <Link to="/ui-utilities">Utilities<span className="badge rounded-pill bg-success float-end">New</span></Link>
                </li>
                <li>
                  <Link to="/ui-grid">{props.t("Grid")}</Link>
                </li>
                <li>
                  <Link to="/ui-images">{props.t("Images")}</Link>
                </li>
                <li>
                  <Link to="/ui-lightbox">{props.t("Lightbox")}</Link>
                </li>
                <li>
                  <Link to="/ui-modals">{props.t("Modals")}</Link>
                </li>
                <li>
                  <Link to="/ui-colors">Colors<span className="badge rounded-pill bg-warning float-end">New</span></Link>
                </li>
                <li>
                  <Link to="/ui-offcanvas">Offcanvas<span className="badge rounded-pill bg-warning float-end">New</span></Link>
                </li>
                <li>
                  <Link to="/ui-pagination">{props.t("Pagination")}</Link>
                </li>
                <li>
                  <Link to="/ui-popover-tooltip">{props.t("Popover & Tooltips")}</Link>
                </li>
                <li>
                  <Link to="/ui-rangeslider">{props.t("Range Slider")}</Link>
                </li>
                <li>
                  <Link to="/ui-session-timeout">{props.t("Session Timeout")}</Link>
                </li>
                <li>
                  <Link to="/ui-progressbars">{props.t("Progress Bars")}</Link>
                </li>
                <li>
                  <Link to="/ui-tabs-accordions">{props.t("Tabs & Accordions")}</Link>
                </li>
                <li>
                  <Link to="/ui-typography">{props.t("Typography")}</Link>
                </li>
                <li>
                  <Link to="/ui-video">{props.t("Video")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="waves-effect">
                <i className="mdi mdi-clipboard-outline"></i>
                <span className="badge rounded-pill bg-success float-end">6</span>
                <span>{props.t("Forms")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/form-elements">{props.t("Form Elements")}</Link>
                </li>
                <li>
                  <Link to="/form-validation">{props.t("Form Validation")}</Link>
                </li>
                <li>
                  <Link to="/form-advanced">{props.t("Form Advanced")}</Link>
                </li>
                <li>
                  <Link to="/form-editors">{props.t("Form Editors")}</Link>
                </li>
                <li>
                  <Link to="/form-uploads">{props.t("Form File Upload")} </Link>
                </li>
                <li>
                  <Link to="/form-xeditable">{props.t("Form Xeditable")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-chart-line"></i>
                <span>{props.t("Charts")}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/apex-charts">
                    {props.t("Apex charts")}
                  </Link>
                </li>

                <li>
                  <Link to="/charts-chartjs" className="dropdown-item">
                    {props.t("Chartjs Chart")}
                  </Link>
                </li>
                <li>
                  <Link to="/sparkline-charts" className="dropdown-item">
                    {props.t("Sparkline Chart")}
                  </Link>
                </li>
                <li>
                  <Link to="/charts-c3" className="dropdown-item">
                    {props.t("C3 Chart")}
                  </Link>
                </li>
                <li>
                  <Link to="/charts-knob" className="dropdown-item">
                    {props.t("Jquery Knob Chart")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-format-list-bulleted-type"></i>
                <span>{props.t("Tables")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tables-basic">{props.t("Basic Tables")}</Link>
                </li>
                <li>
                  <Link to="/tables-datatable">{props.t("Data Tables")}</Link>
                </li>
                <li>
                  <Link to="/tables-responsive">
                    {props.t("Responsive Table")}
                  </Link>
                </li>
                <li>
                  <Link to="/tables-editable">{props.t("Editable Table")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-album"></i>
                <span>{props.t("Icons")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/icons-materialdesign">
                    {props.t("Material Design")}
                  </Link>
                </li>
                <li>
                  <Link to="/icons-ion">{props.t("Ion Icons")}</Link>
                </li>
                <li>
                  <Link to="/icons-fontawesome">{props.t("Font Awesome")}</Link>
                </li>
                <li>
                  <Link to="/icons-themify">{props.t("Themify Icons")}</Link>
                </li>
                <li>
                  <Link to="/icons-dripicons">{props.t("Dripicons")}</Link>
                </li>
                <li>
                  <Link to="/icons-typicons">{props.t("Typicons Icons")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="waves-effect">
                <span className="badge rounded-pill bg-danger float-end">2</span>
                <i className="mdi mdi-google-maps"></i>
                <span>{props.t("Maps")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/maps-google">{props.t("Google Maps")}</Link>
                </li>
                <li>
                  <Link to="/maps-vector">{props.t("Vector Maps")}</Link>
                </li>

              </ul>
            </li>

            <li className="menu-title">Extras</li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-account-box"></i>
                <span>{props.t("Authentication")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-login">{props.t("Login")}</Link>
                </li>
                <li>
                  <Link to="/pages-register">{props.t("Register")}</Link>
                </li>
                <li>
                  <Link to="/page-recoverpw">
                    {props.t("Recover Password")}
                  </Link>
                </li>
                <li>
                  <Link to="/auth-lock-screen">{props.t("Lock Screen")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-text-box-multiple-outline"></i>
                <span>{props.t("Extra Pages")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-timeline">{props.t("Timeline")}</Link>
                </li>
                <li>
                  <Link to="/pages-invoice">{props.t("Invoice")}</Link>
                </li>
                <li>
                  <Link to="/pages-directory">{props.t("Directory")}</Link>
                </li>
                <li>
                  <Link to="/pages-blank">{props.t("Blank Page")}</Link>
                </li>
                <li>
                  <Link to="/pages-404">{props.t("Error 404")}</Link>
                </li>
                <li>
                  <Link to="/pages-500">{props.t("Error 500")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-share-variant"></i>
                <span>{props.t("Multi Level")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Level 1.1")}</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Level 1.2")}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">{props.t("Level 2.1")}</Link>
                    </li>
                    <li>
                      <Link to="/#">{props.t("Level 2.2")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
