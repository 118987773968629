import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Form,
  Input,
  FormFeedback,
  UncontrolledAlert,
  Button,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"

const Login = () => {
  document.title = "Login | Exyst - Admin Dashboard"

  const navigate = useNavigate()
  const [alertVisible, setAlertVisible] = useState(false)
  const [error, setError] = useState("")
  const [passwordType, setPasswordType] = useState("password")

  const validation = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async values => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/form/exyst/login`,
        {
          method: "POST",
          body: JSON.stringify(values),
          headers: {
            "Content-Type": "application/json",
          },
        },
      )

      const json = await response.json()

      if (response.ok) {
        localStorage.setItem("token", json.token)
        navigate("/submissions")
      } else {
        setError(json.message)
        setAlertVisible(true)
        setTimeout(() => setAlertVisible(false), 5000)
      }
    },
  })

  if (localStorage.getItem("token")) {
    navigate("/submissions")
  }

  const changeInputType = () => {
    if (passwordType === "password") {
      setPasswordType("text")
    } else {
      setPasswordType("password")
    }
  }
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              {alertVisible && ( // Conditionally render the alert
                <UncontrolledAlert color="danger">
                  <strong>Error! </strong> {error}
                </UncontrolledAlert>
              )}
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        src="exyst-logo.svg"
                        alt=""
                        height="40"
                        className="auth-logo-dark"
                      />
                      <img
                        src="exyst-logo.svg"
                        alt=""
                        height="40"
                        className="auth-logo-light"
                      />
                    </Link>
                  </h3>

                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Welcome Back !
                    </h4>
                    <p className="text-muted text-center">
                      Sign in to continue to ExystNow - Admin.
                    </p>

                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={validation.handleSubmit}
                    >
                      <div className="mb-3">
                        <Label htmlFor="username">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email}
                          invalid={
                            validation.touched.email && validation.errors.email
                          }
                        />
                        {validation.touched.email &&
                          validation.errors.email && (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          )}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="userpassword">Password</Label>
                        <div className="input-group">
                          <Input
                            name="password"
                            value={validation.values.password}
                            type={passwordType}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                            }
                          />
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="validationTooltipUsernamePrepend"
                              onClick={changeInputType}
                              role="button"
                            >
                              {passwordType === "password" ? (
                                <span className="mdi mdi-eye" />
                              ) : (
                                <span className="mdi mdi-eye-off" />
                              )}
                            </span>
                          </div>

                          {/* <Button onClick={changeInputType} color="primary">Show</Button> */}
                          {validation.touched.password &&
                            validation.errors.password && (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            )}
                        </div>
                      </div>

                      <Row className="mb-3 mt-4">
                        <div className="col-12 text-center">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Login.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Login)
