import React from "react"
import { Navigate } from "react-router-dom"


import Pages404 from "../pages/Extra Pages/pages-404";
import Pages500 from "../pages/Extra Pages/pages-500";

import AllSubmissions from "pages/submissions/AllSubmissions"
import SingleSubmission from "pages/submissions/SingleSubmission";

const userRoutes = [

  { path: "/submissions", component: <AllSubmissions /> },
  { path: "/single-submission/:id", component: <SingleSubmission /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/submissions" />,
  },
]

const authRoutes = [


  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  // { path: "/pages-login", component: <Login1 /> },
  // { path: "/pages-register", component: <Register1 /> },
  // { path: "/page-recoverpw", component: <Recoverpw /> },
  // { path: "/auth-lock-screen", component: <LockScreen /> },
]

export { userRoutes, authRoutes }