import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
const Authmiddleware = (props) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login", { state: { from: props.location } });
      return;
    }

    let decodedToken;
    try {
      decodedToken = jwtDecode(token);
    } catch (error) {
      console.error("Invalid token.", error);
      navigate("/login", { state: { from: props.location } });
      return;
    }

    const currentDate = new Date();
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      localStorage.clear();
      navigate("/login", { state: { from: props.location } });
    }
  }, [token, navigate, props.location]);

  return <>{props.children}</>;
};

export default Authmiddleware;
